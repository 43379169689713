<footer class="shop-home-footer d-flex flex-column align-items-center ">
    <div class="container footer-branding">
        <div class="d-flex justify-content-center">
            <div class="d-flex" *ngFor=" let image of footerImage">
                <img [src]="image.imgSrc" alt="">
            </div>
        </div>

        <div class="d-flex justify-content-center pt-1 footer-tagline">
            <span>Stay Healthy, Stay Happy!</span>
        </div>
        <div class="d-flex justify-content-center">
            <span class="footer-manufacture">Made with ❤️ in India</span>
        </div>
    </div>
</footer>